<template>
  <div class="wrapper">
      <div class="padTop"></div>
      <div class="animationWrapper1"></div>
      <div class="animationWrapper2">
          <p class="p1">技术驱动、金融赋能</p>
          <p class="p2"> 依据货物的多样性，对货物运输需求做到对接及时、沟通专业、回馈有效，通过好三由系统对平台运力库资源精确匹配，提供最佳运输方案，满足不同用户不同货物的陆路运输需求；</p>
      </div>
      
      <div class="animationWrapper3"></div>

  </div>
</template>

<script>
export default {
  props: {
  },
  data () {
    return {
    }
  },
  created () {},
  mounted () {},
  watch: {},
  computed: {},
  methods: {},
  components: {},
}
</script>
<style scoped lang="scss">
div{line-height:150%;padding:0;margin:0;font-size:12px}
.padTop{height:55px}
.animationWrapper1{width:200px;height:100px;animation:animation1 2s  infinite alternate running;}
@keyframes animation1{
    from {background:red;margin-left:10px}
    to {background:yellow;margin-left:200px}
}
.animationWrapper2{width:100%;height:180px;background:#ccc;margin-top:10px;padding-top:120px;padding-bottom:10px;overflow:hidden;transition:padding-top 2s;
    .p1{width:200px;margin:0px auto;line-height:30px;height:30px;}
    .p2{width:200px;margin:0 auto}
}
.animationWrapper2:hover{padding-top:20px}
.animationWrapper3{width:100px;height:100px;background:#ccc;transition:width 2s;-webkit-transition:width 2s}
.animationWrapper3:hover{width:300px}
</style>