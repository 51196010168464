<template>
  <div class="wrapper" data-info="运力中心">
      <!--第一部分大图片-->
      <div class="content1">
          <img src="../assets/newversion/goods_m.png"/>
      </div>
      <div class="zoneTitle">
        <h2>货力中心</h2>
        <div class="littleLine"></div>
        <div class="infoWrapper">
            <div class="info">船泊查询</div>
        </div> 
    </div>
    <div class="dataTitle">
        <div>船舶名称</div>
        <div>船舶吨位</div>
        <div>目前在港</div>
        <div>信用认证</div>
        <div>空船日期</div>
        <div>详情查看</div>
    </div>
    <div class="dataList" id="dataList">
        <div class="dataItem" >
            <div>预信货</div>
            <div>2000吨</div>
            <div>南通港</div>
            <div>实名、船舶认证</div>
            <div>3.30</div>
            <div @click="show(1)">查看</div>
        </div>
        <div class="dataItem"  >
            <div>预信货</div>
            <div>2000吨</div>
            <div>南通港</div>
            <div>实名、船舶认证</div>
            <div>3.30</div>
            <div>查看</div>
        </div>
        <div class="dataItem"  >
            <div>预信货</div>
            <div>2000吨</div>
            <div>南通港</div>
            <div>实名、船舶认证</div>
            <div>3.30</div>
            <div>查看</div>
        </div>
        <div class="dataItem" >
            <div>预信货</div>
            <div>2000吨</div>
            <div>南通港</div>
            <div>实名、船舶认证</div>
            <div>3.30</div>
            <div>查看</div>
        </div>
        <div class="more">
            <div>获取更多</div>
            <img src="../assets/newversion/more_1.png" alt="">
        </div>
    </div>



    <div class="zoneTitle">
        <h2>存盘中心</h2>
        <div class="littleLine"></div>
        <div class="infoWrapper">
            <div class="info">货盘查询</div>
        </div> 
    </div>
    <div class="dataTitle">
        <div>发货地</div>
        <div>卸货地</div>
        <div>货物名称</div>
        <div>货物吨位</div>
        <div>装载日期</div>
        <div>我要承运</div>
    </div>
    <div class="dataList" id="dataList">
        <div class="dataItem">
            <div>预信货</div>
            <div>2000吨</div>
            <div>南通港</div>
            <div>实名、船舶认证</div>
            <div>3.30</div>
            <div @click="show(2)">查看</div>
        </div>
        <div class="dataItem">
            <div>预信货</div>
            <div>2000吨</div>
            <div>南通港</div>
            <div>实名、船舶认证</div>
            <div>3.30</div>
            <div  >查看</div>
        </div>
        <div class="dataItem">
            <div>预信货</div>
            <div>2000吨</div>
            <div>南通港</div>
            <div>实名、船舶认证</div>
            <div>3.30</div>
            <div  >查看</div>
        </div>
        <div class="dataItem">
            <div>预信货</div>
            <div>2000吨</div>
            <div>南通港</div>
            <div>实名、船舶认证</div>
            <div>3.30</div>
            <div  >查看</div>
        </div>
        <div class="more">
            <div style="color:#000">获取更多</div>
            <img src="../assets/newversion/more_2.png" alt="">
        </div>
    </div>

    <div class="mask" id="mask" v-if="maskShow">
        <div class="show show1" id="show1" v-if="maShow1" >
            <div class="showHeader">联系方式</div>
            <div class="close" id="close1" @click="close(1)" >&times;</div>
            <div class="showInfo"> 微信扫描“货主版小程序”，获取更多内容</div>
            <div class="code">
                <img src="../assets/newversion/wechat_code_owner.jpg" />
            </div>
            <div class="codeName">货主版小程序</div>
            <div class="maBtn">扫码获取</div>
        </div>
        <div class="show show2" id="show2" v-if="maShow2">
            <div class="showHeader">联系方式</div>
            <div class="close" id="close2" @click="close(2)">&times;</div>
            <div class="showInfo"> 微信扫描“船东版APP”、“船东版小程序” ,获取更多内容</div>
            <div class="codeItemWrapper">
                <div class="codeItem">
                    <div class="code">
                        <img src="../assets/newversion/android_ios.png" />
                    </div>
                    <div class="codeName">船东版APP</div>
                </div>
                <div class="codeItem">
                    <div class="code">
                        <img src="../assets/newversion/wechat_code_shipper.jpg" />
                    </div>
                    <div class="codeName">船东版小程序</div>
                </div>
                
                


            </div>
           
            <div class="maBtn">扫码获取</div>
        </div>
        
        

    </div>
    
   

  </div>
</template>

<script>
export default {
  props: {
  },
  data () {
    return {
        maskShow:false,
        maShow1:false,
        maShow2:false
    }
  },
  created () {},
  mounted () {},
  watch: {},
  computed: {},
  methods: {
    show(flag){
         this.maskShow = true;
         this.maShow1 = false;
         this.maShow2 = false;
        if(flag==1){
          this.maShow1 = true;
        }else{
            this.maShow2 = true;
        }
    },
    close(flag){
         this.maskShow = false;
         this.maShow1 = false;
         this.maShow2 = false;
    }
  },
  components: {},
}
</script>
<style scoped>
.wrapper{font-size:12px;background:#fff}
.content1 img{display:block;width:100%}
.zoneTitle{text-align:center;background:#F1F5FF;height:75px;padding-top:15px}
.zoneTitle h2{font-size:16px}
.zoneTitle .littleLine{width:20px;height:2px;border-radius:2px;background:#7F7F7F;margin:0 auto;margin-top:-7px}
.infoWrapper{width:360px;margin:0 auto;margin-top:-4px}
.info{background:#fff;width:80px;height:25px;text-align:center;line-height:25px;border-radius:8px;font-weight:bold;font-size:13px;margin-top:0px}
.dataTitle{width:360px;height:50px;display:flex;justify-content: space-around;line-height:50px;margin:0 auto;margin-top:50px;background:#F1F5FF;font-size:12px;border-radius:14px;color:#989BA4}
.dataTitle div{width:16.5%;text-align:center}

.dataItem{height:40px;line-height:40px;text-align:center;width:360px;display: flex;justify-content: space-around;margin:0 auto;font-size:12px;cursor: pointer;}
.dataItem:active{box-shadow: 0 0 10px #ccc;border-radius:12px;}
.dataItem div{width:16.5%;text-align: center;overflow:hidden}
.more{text-align:center;color:#5F74FF;font-size:13px;font-weight: bold;margin-top:20px;cursor:pointer}
.more img{display:block;margin:10px auto;margin-bottom:15px;width:10px}

.mask{position:fixed;top:0;right:0;left:0;bottom:0;margin:auto;background:rgba(0,0,0,0.5);z-index: 100;}
.show{width:325px;height:305px;position:fixed;top:0;right:0;left:0;bottom:0;margin:auto;background:#ffff;padding:0 10px}
.showHeader{line-height:50px;font-size:14px;font-weight:bold;text-align: left;border-bottom:1px solid #ccc;height:44px;color:#575757}
.close{position:absolute;right:10px;top:0;width:43px;height:44px;font-size:24px;border:0px solid red;text-align:right;line-height:46px;cursor:pointer;color:#7E7E7E}

.showInfo{text-align: left;line-height:45px;font-size:12px;color:#555;text-indent:0px}
.show .code{width:110px;height:110px;margin:0 auto;margin-top:10px;border-radius:16px;box-shadow: 0 0 30px #ccc;padding-top:10px}
.show .code img{width:90px;margin:0px auto;display:block}
.codeName{font-size:14px;font-weight:bold;text-align:center;margin-top:15px;color:#555}
.maBtn{width:93px;height:32px;color:#fff;text-align:center;line-height:32px;border-radius:6px;margin:0 auto;margin-top:10px;background:#3F5ADD;font-size:13px;line-height:32px;box-shadow:0 0 10px #ccc}
.codeItemWrapper{display: flex;justify-content: space-around;width:85%;margin:0 auto}
 


</style>