<template>
    <div class="fullPage">
        <div class="music-icon" style="top: 19px; right: 20px;" @click="playMusic">
            <img src="../assets/yinle.png" >
            <audio ref="audio" id="audio" autoplay loop='loop'  src="https://www.yundundun.com/2023year/1.mp3" ></audio>
        </div>
      <van-swipe style="height: 100%;" vertical :loop="false" :show-indicators="false" @change="onChange">
        <van-swipe-item class="section" v-for="item in num" :key="item"><img :src="setUrl(item)"></van-swipe-item>
      </van-swipe>
      <div class="paging-icon" :class="current==len-1?'none':''"></div>
    </div>
  </template>
  <script>
  export default {
    name: "Home",
    data() {
      return {
        fullpage: {
          current: 1, // 当前的页面编号
          isScrolling: false, // 是否在滚动,是为了防止滚动多页，需要通过一个变量来控制是否滚动
          deltaY: 0 // 返回鼠标滚轮的垂直滚动量，保存的鼠标滚动事件的deleteY,用来判断是往下还是往上滚
        },
        startTime: undefined, // 记录触摸开始的时间
        startX: undefined, // 记录触摸开始的X坐标，本次主要实现的是上下滑动，所以当前坐标不做强制要求
        startY: undefined, // 记录触摸开始的Y坐标
        stopBackgroundMusic:true,
        goodsOwner:[],
        num:[],
        len:0,
        url:'https://www.yundundun.com/2023year/',
        current:0,
      };
    },
    methods: {
      onChange(index) {
        this.current=index;
      },
    // 播放音乐
    audioAutoPlay(){
      let audio = document.getElementById("audio");
      audio.play();
      document.removeEventListener("touchstart", this.audioAutoPlay);
    },
    musicNow(){
        // 自动播放音乐效果，解决微信自动播放问题
      document.addEventListener('touchstart',this.audioAutoPlay);
      document.addEventListener('WeixinJSBridgeReady', this.audioAutoPlay);
      let oAudio = document.querySelector("#audio");
      oAudio.onended = function () {//播放完毕，重新循环播放
        oAudio.load();
        oAudio.play();
      }
      this.audioAutoPlay();
    },
    // //停止播放音乐
    // stopMusic(){
    //   let oAudio = document.querySelector("#audio");
    //   oAudio.pause();
    //   this.stopBackgroundMusic = false;
    // },
    //继续播放音乐
    playMusic(){
      this.musicNow();
      this.stopBackgroundMusic = true;
    },
    setUrl(item){
        return this.url+'/'+item+'.jpg'
    }
    },
    mounted () {
        this.musicNow()
        let report = require('../assets/report.json').goodsOwner
        let num='',result=[]
        report.find(item=>{
          if(item.id==this.$route.query.id){
            num=item.num
          }
        })
        this.len=num
        for (let i = 1; i <= num; i++) {
          result.push(i);
        }
        this.num=result
        this.url=this.url+this.$route.query.id
    },
  };
  </script>
  <style scoped lang="scss">
  .fullPage {
    height: 100vh; //一定要设置，保证占满
    overflow: hidden; //一定要设置，多余的先隐藏
  }
  .fullPageContainer {
    width: 100%;
    height: 100vh;
    transition: all linear 0.5s;
  }
  .section {
    width: 100%;
    height: 100vh;
    background-position: center center;
    background-repeat: no-repeat;
  }
  img{
    width:100%;
    height:100%;
    object-fit: cover;
  }
  .music-icon{
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    top: 40px;
    right: 40px;
    border: 1px solid #fff;
    border-radius: 50%;
    background: rgba(0,0,0,.3);
    -webkit-box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
    -webkit-transform-origin: top right;
    transform-origin: top right;
    cursor: pointer;
    z-index: 1;
  }
  @keyframes rotate-music {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
  }
  .music-icon img{
    width: 20px;
    height: 20px;
    -webkit-animation: rotate-music 3s linear infinite;
    animation: rotate-music 3s linear infinite;
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  @keyframes slide-next-page {
    0% {
        -webkit-transform: translateY(-0.26rem);
        transform: translateY(-0.26rem);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-0.56rem);
        transform: translateY(-0.56rem);
        opacity: 0;
    }
  }
  .paging-icon{
    position: fixed;
    left: 50%;
    bottom: 32px;
    margin-left: -25px;
    z-index: 4;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 50px;
    height: 50px;
    -webkit-animation: slide-next-page 1.5s linear infinite;
    animation: slide-next-page 1.5s linear infinite;
    cursor: pointer;
  }
  .none{
    display: none;
  }
.paging-icon:after, .paging-icon:before {
    content: "";
    width: 14px;
    height: 14px;
    -webkit-box-shadow: 2px -2px 1px 2px rgba(0,0,0,.08);
    box-shadow: 2px -2px 1px 2px rgba(0,0,0,.08);
    border-color: #fff;
    border-style: solid;
    border-width: 4px 4px 0 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
  </style>
  
  