<template>
    <div class="wrapper">

        <img src="../assets/images/banners/b1.png" alt="">
        <img src="../assets/images/banners/b2.png" alt="">
        <img src="../assets/images/banners/b3.png" alt="">
        <img src="../assets/images/banners/b4.png" alt="">
        <img src="../assets/images/banners/b5.png" alt="">
        <img src="../assets/images/banners/b61.jpg" alt="">
        <img src="../assets/images/banners/b62.jpg" alt="">
        <img src="../assets/images/banners/b7.jpg" alt="">
        <img src="../assets/images/banners/b71.jpg" alt="">
        <div class="box">
            <img src="../assets/images/banners/b8.jpg" alt="">
            <a href="tel:02583766081" class="tel"><img src="../assets/images/banners/tel2.png" alt="" ></a>
            <a href="tel:18757570240" class="tel tel2"><img src="../assets/images/banners/tel1.png" alt="" ></a>

        </div>
     

    </div>
</template>

<script>
export default {
    props: {},
    data() {
        return {};
    },
    created() {},
    mounted() {},
    watch: {},
    computed: {},
    methods: {},
    components: {},
};
</script>
<style scoped>
.wrapper img {
    width: 375px;
    display: block;
    width: 100%;
}
.wrapper img {
    margin-top: -1px;
}
.tel  img{
    position: absolute;
    top: 30px;
    width: 60px;
    display: block;
  
    right: 15px;
}
.tel.tel2  img{
    position: absolute;
    top: 30px;
    width: 60px;
    display: block;
  
    left: 15px;
}

.box {
    position: relative;
}
.box2 .tel img{
     position: absolute;
    top: 30px;
    width: 60px;
    display: block;
    left: 15px;
}
</style>