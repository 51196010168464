<template>
    <div class="wrapper">
        <div class="container">
            <div>
                <a-input-search placeholder="输入城市名" v-model.trim="cityName" enter-button="搜索" @search="onSearch" size="large"/>
            </div>
            <div class="header">
                <div>{{currentCity.city}}<span>{{currentCity.wea}}</span><span> {{currentCity.date}} {{currentCity.update_time}}</span> </div>
                <div><span>温度：{{currentCity.tem}} </span>
                    <span>风向：{{currentCity.win}} </span>
                    <span>风速：{{currentCity.win_speed}} </span>
                    <span>湿度:{{currentCity.humidity}}</span>
                </div>
            </div>
            <div class="weather_list" style="background:#f1f1f1;width:375px">
                <div class="item" v-for="(item,index) in list" :key="index">
                    <div class="item_name">
                        <a-icon type="bank" style="color:#FF7F00" />
                        {{item.city}}
                        <span class="wea">{{item.wea}}</span>
                    </div>
                    <div class="item_address">
                        {{item.tem}}℃ {{item.win}}{{item.win_speed}} 空气温度：{{item.humidity}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { fetchWeather, fetchWeatherList } from "@/request/api.js";
export default {
    props: {},
    data() {
        return {
            currentCity: {},
            list: [],
            currentIp: "",
            cityName:''
        };
    },
    created() {
        this.getList();
    },
    mounted() {
        this.initIp();
    },
    watch: {},
    computed: {},
    methods: {
        getCurrent() {
            fetchWeather({ ip: this.currentIp }).then((res) => {
                this.currentCity = res;
            });
        },
        getList() {
            let city = [
                "北京",
                "上海",
                "广州",
                "深圳",
                "南京",
                "杭州",
                "苏州",
                "成都",
                "武汉",
                "合肥",
                "扬州",
            ];
            fetchWeatherList({
                cityid: "101050101,101060101,101070101,101080101,101090101,101100101,101110101,101120101,101130101,101140101,101150101,101160101,101170101,101180101,101190101,101200101,101210101,101220101,101230101,101240101,101240403,101250101,101260101,101270101,101280101,101290101,101300101,101310101,101320101,101330101,101340101",
            }).then((res) => {
                console.log("reslist", res);
                this.list = JSON.parse(res);
            });
        },
        initIp() {
            if (localStorage.getItem("currentIp")) {
                this.currentIp = localStorage.getItem("currentIp");
                this.getCurrent();
            } else {
                setTimeout(() => {
                    this.currentIp = localStorage.getItem("currentIp");
                    this.getCurrent();
                }, 500);
            }
        },
        onSearch(){
            console.log(this.cityName)
            let cityName = this.cityName.replace("市",'')
            cityName = this.cityName.replace("县",'')
            cityName = this.cityName.replace("区",'')
            cityName = this.cityName.replace("镇",'')
            cityName = this.cityName.replace("乡",'')
            this.cityName = cityName;
            fetchWeather({ city: this.cityName}).then((res) => {
                if(res.errcode){                   
                    this.$message.error(res.errmsg);
                }else{
                    this.currentCity = res;
                }
            });
        }
    },
    components: {},
};
</script>
<style scoped>
@import "../css/iconfont/iconfont.css"; /*这个分号一定要写，要不会报错*/
.wrapper {
    background: #f3f3f3;
    padding-top: 5px;
}
.container {
    width: 350px;
    border: 0px solid red;
    margin: 0 auto;

    font-size: 12px;
    padding-bottom: 20px;
    padding-top:12px
}
.banner {
    width: 100%;
    border-radius: 4px;
}
.header {
    height: 100px;
    line-height: 50px;
    font-size: 16px;
    background: #fff;
    margin-top: 10px;
    border-radius: 4px;
    padding-left: 10px;
}
.header div:nth-child(1) {
    font-size: 20px;
    font-weight: bold;
    height: 50px;
    line-height: 78px;
}
.header div:nth-child(1) span {
    font-size: 12px;
    font-weight: normal;
    margin-left: 15px;
}
.header div:nth-child(2) span {
    margin-right: 12px;
    font-size: 12px;
}

.item {
    background: #fff;
    height: 90px;
    margin-top: 15px;
    padding-left: 10px;
    width: 350px;
}
.item .item_name {
    color: #4461e9;
    font-size: 15px;
    height: 45px;
    line-height: 60px;
}
.item .item_name .wea {
    color: #333;
    margin-left: 20px;
    font-size: 12px;
}
.item .item_address {
    font-size: 14px;
    height: 20px;
    line-height: 18px;
    padding-left: 2px;
}
</style>