<template>
    <!--
<div class="dial" v-wechat-title="$route.meta.title">
-->

    <div class="dial">

        <!--
            <div class="times">抽奖次数{{LuckyClick}}</div>
        -->
        <div class="goodMsg">
            1599***3095刚刚抽中了爱奇艺会员
        </div>
        <div class="title"> 
             <img class="title_img" src="../assets/happy51/title.png" />
        </div>

        <!-- 转盘包裹 -->
        <div class="rotate">
            <!-- 绘制圆点 -->
            <div class="circleWrapper">
                <div :class="'circle circle_'+index" v-for="(item,index) in circleList" :key="index" :style="{background:index%2==0?colorCircleFirst:colorCircleSecond}"></div>

            </div>

            <!-- 转盘图片 -->
            <img class="dish" src="../assets/happy51/dial.png" :style="{transform:rotate_deg,transition:rotate_transition}" />
            <!-- 指针图片 -->
            <img class="pointer" src="../assets/happy51/click.png" />
        </div>
        <div class="btnWrapper">
            <div class="btn btn1" @click="start">点我抽奖</div>
            <!--
                 <div class="btn btn2" @click.stop="share">还想抽<br />快来点我吧</div>
            -->
           
        </div>

        <div class="word">
            <div class="wordtitle">活动时间：</div>
            <div>2023年5月1日8:00至5月2日22:00</div>
        </div>
        <div class="word">
            <div class="wordtitle">参与方式</div>
            <div>所有活动用户点击活动页面完成注册后即可参与抽奖</div>
        </div>
        <div class="word" @click="showModal">
            <div class="wordtitle"><span>了解更多活动详情详见活动规则</span></div>
        </div>

        <a-modal v-model="visible" title="五一活动规则" ok-text="确认" cancel-text="取消" @ok="hideModal" :footer="false" :bodyStyle="{paddingTop:0}" :centered="true">
            <div class="desc">
                <div> 一、活动时间：2023年5月1日8:00至5月2日22:00</div>

                <div>二、活动对象：船民</div>

                <div> 三、奖品设置：</div>
                一等奖：帮你买单（2000元以内商品随心选）<br />
                二等奖：小米米家扫地机器人（价值999元）<br />
                三等奖：精美茶具（价值199元）<br />
                四等奖：视频会员月卡（价值20元）<br />
                五等奖：5元现金红包<br />
                六等奖：惊喜红包<br />
                七等奖： 随机红包<br />

                <div> 四、抽奖规则：</div>
                1.用户点击活动页面进行注册，注册完成后获得1次免费抽奖机会；<br />
                2.每个用户转发活动链接可获得1次抽奖机会，活动期间至多可额外获得2次抽奖机会；<br />
                3.活动期间，每个用户至多可获得3次抽奖机会。<br />

                <div>五、中奖规则</div>
                1.活动中奖率100%，活动真实有效；<br />
                2.中奖用户需为真实有效船东，身份不实将取消获奖资格；<br />
                3.每条船仅限绑定一位船东，以首次信息绑定为准，后续绑定船东不参与兑奖环节。<br />

                <div>六、兑奖方式</div>
                1.中奖用户需扫码进入好三由官方福利群领取奖品；<br />
                2.兑奖时间为5月1日至5月2日8:00—18:00，逾期未领奖者视为自动放弃中奖资格。<br />

                <div>七、本活动最终解释权归江苏好三由所有。</div>

            </div>
        </a-modal>
        <template v-if="visible1">
            <div class="mask" @click.stop="close1">
                <div class="">
                    <img src="../assets/happy51/res1.png" alt="" class="res1_img" />
                    <img src="../assets/happy51/ma.jpg" alt="" class="ma_img" />
                </div>
                <div class="result result1">
                    <div>恭喜你！老铁！</div>
                    <div class="">{{goodsName}}</div>
                    <div>扫码进群即可马上领奖~</div>
                </div>
                <div class="share_btn1" @click.stop="share">转发活动<br />再来一次抽奖机会</div>
                <div class="close close1" @click="close1" style="display:none">&times;</div>
            </div>
        </template>
        <template v-if="visible2">
            <div class="mask" @click.stop="close2">
                <div class="">
                    <img src="../assets/happy51/res2.png" alt="" class="res1_img" />
                </div>
                <div class="result result1 result2" v-if="visible2_8">
                    <div>太抱歉啦！</div>
                    <div>今天的奖品已经抢完了，<br/>请明天赶早哦！</div>
                </div>
                <div class="result result1 result2" v-if="visible2_9">
                    <div> 老铁，你已经抽过奖了</div>
                    <div style="margin-top:20px">接着转发接着薅</div>
                </div>
                <div class="share_btn1 share_btn2" v-if="visible2_9" style="line-height:32px" @click.stop="share">点我转发再抽一次</div>
                <div class="close close1" @click="close2" style="display:none">&times;</div>
            </div>
        </template>
        <template v-if="visible3">
            <div class="mask" @click.stop="close3">
                <div class="">
                    <img src="../assets/happy51/share.png" alt="" class="share_img" />
                </div>
               
                
                <div class="close close1" @click="close3" style="display:none">&times;</div>
            </div>
        </template>

    </div>
</template>
 
<script>
import {getLucky} from "../request/api.js"
var light_timer; //灯光定时器

export default {
    name: "dial",
    data() {
        return {
            LuckyClick: 3,
            circleList: [], //原点设置
            colorCircleFirst: "#FE4D32", //圆点颜色
            colorCircleSecond: "#fff", //圆点颜色
            cat: 45, //总共8个扇形区域，每个区域约45度
            isAllowClick: true, //是否能够点击
           // rotate_deg: 0, //指针旋转的角度
            rotate_deg: "rotate(" + 90+ "deg)", //指针旋转的角度
            rotate_transition: "transform 3s ease-in-out", //初始化选中的过度属性控制
            visible: false,
            goodsList: [
                //奖品列表
                { type: 1, name: "精美茶具" },
                { type: 2, name: "随机现金红包" },
                { type: 3, name: "5元现金红包" },
                { type: 4, name: "视频会员月卡" },
                { type: 5, name: "帮你买单(2000元内)" },
                { type: 6, name: "米家扫地机器人" },
                { type: 7, name: "惊喜红包" },
            ],
            visible1: false,
            goodsName: "", //中的奖品
            visible2: false,
            vsible2_8:false,
            vsible2_9:false,
            visible3:false

        };
    },

    components: {
        // Calendar: Calendar
    },
    created() {
        this.showcircleList();
    },

    watch: {},

    mounted() {},

    methods: {
        close1() {
            console.log("close111")
            this.visible1 = false;
            this.rotate_deg = "rotate(" + 90 + "deg)"; //定时器关闭的时候重置角度
            this.rotate_transition = "";
            this.isAllowClick = true;

            //指针角度重置
        },
        close2() {
            this.visible2 = false;
            this.rotate_deg = "rotate(" + 90 + "deg)"; //定时器关闭的时候重置角度
            this.rotate_transition = "";
            this.isAllowClick = true;

            //指针角度重置
        },
        close3() {
            this.visible2 = this.visible3 = this.visible1=false;
            this.rotate_deg = "rotate(" + 90 + "deg)"; //定时器关闭的时候重置角度
            this.rotate_transition = "";
            this.isAllowClick = true;

            //指针角度重置
        },
        share(){
            this.visible3 = true
        },
        //绘制圆点设置
        showcircleList() {
            let circleList = [];
            for (var i = 0; i < 16; i++) {
                circleList.push(i);
            }
            this.circleList = circleList;
            this.light();
        },

        //闪动效果
        light: function () {
            var that = this;
            clearInterval(light_timer);
            light_timer = setInterval(function () {
                if (that.colorCircleFirst == "#FE4D32") {
                    that.colorCircleFirst = "#fff";
                    that.colorCircleSecond = "#FE4D32";
                } else {
                    that.colorCircleFirst = "#FE4D32";
                    that.colorCircleSecond = "#fff";
                }
            }, 300); //设置圆点闪烁的效果
        },

        start() {
            if (!this.isAllowClick) return;

            this.isAllowClick = false;
            this.requestRes().then((res) => {
                console.log("aaa>>>", res);
                if (res.num < 8) {
                    this.rotate_transition = "transform 3s ease-in-out";
                    this.rotate_deg = "rotate(" + res.angle + "deg)"; 
                    setTimeout(() => {
                        //旋转3秒停下来再过1秒,即4秒出现弹窗
                        if (res.num < 8) {
                            console.log("cccc");
                            this.visible1 = true;
                            this.goodsName = this.goodsList.filter(
                                (item) => item.type == res.num
                            )[0].name;
                        }
                    }, 4000);
                }else{
                    this.visible2 = true;
                    if(res.num == 8){
                        //被今天的奖抢完了
                        this.visible2_8 = true;
                        this.visible2_9 = false;
                    }else if(res.num ==9){
                        //已经抽过奖了，没有抽奖机会了
                        this.visible2_8 = false;
                        this.visible2_9 = true;

                    }
                    
                }
            });

            return false;
            //this.rotating();
        },
        requestRes() {
            //
            getLucky({source:1,phone:"15996263095"}).then(res=>{
                console.log("res>>",res)
            })
            return new Promise((fn1, fn2) => {
                setTimeout(() => {
                    let _num = Math.ceil(Math.random() * 9); // 1-7随机数
                    console.log("RAndom>>>num", _num);
                    
                    let _angle = 360 * 5;
                    if (_num == 1) {
                        _angle += 280;
                    } else if (_num == 2) {
                        _angle += 250;
                    } else if (_num == 3) {
                        _angle += 200;
                    } else if (_num == 4) {
                        _angle += 170;
                    } else if (_num == 5) {
                        _angle += 100;
                    } else if (_num == 6) {
                        _angle += 20;
                    } else if (_num == 7) {
                        _angle += 330;
                    }

                    fn1({ angle: _angle, num: _num });
                }, 1000);
            });
        },

        rotating() {
            if (!this.isAllowClick) return;
            this.isAllowClick = false;
            this.rotate_transition = "transform 3s ease-in-out";
            this.LuckyClick--;
            var rand_circle = 5; //默认多旋转5圈
            //   var winningIndex = Math.floor(Math.random() * 8); //获奖的下标   0-7   没有概率每个平均
            var winningIndex = this.set(); //设置了概率的

            console.log(winningIndex);
            var randomDeg = 360 - winningIndex * 45; //当前下标对应的角度    45是总共8个扇形区域，每个区域约45度

            var deg = rand_circle * 360 + randomDeg; //将要旋转的度数  由于是顺时针的转动方向需要用360度来减
            this.rotate_deg = "rotate(" + deg + "deg)"; ///********* */

            var that = this;
            setTimeout(function () {
                that.isAllowClick = true;
                that.rotate_deg = "rotate(" + 0 + "deg)"; //定时器关闭的时候重置角度
                that.rotate_transition = "";

                if (winningIndex == 0) {
                    alert("恭喜您，IphoneX");
                } else if (winningIndex == 1) {
                    alert("恭喜您，获得10元现金");
                } else if (winningIndex == 2) {
                    alert("很遗憾，重在参与");
                } else if (winningIndex == 3) {
                    alert("恭喜您，获得30元现金");
                } else if (winningIndex == 4) {
                    alert("恭喜您，获得20元现金");
                } else if (winningIndex == 5) {
                    alert("恭喜您，获得50元现金");
                } else if (winningIndex == 6) {
                    alert("恭喜您，获得5元现金");
                } else if (winningIndex == 7) {
                    alert("恭喜您，获得100元现金");
                }
            }, 3500);
        },

        //设置概率
        set() {
            var winIndex;
            //方法2
            var __rand__ = Math.random();
            if (__rand__ < 0.3) winIndex = 2;
            else if (__rand__ < 0.55) winIndex = 6;
            else if (__rand__ < 0.75) winIndex = 1;
            else if (__rand__ < 0.85) winIndex = 4;
            else if (__rand__ < 0.92) winIndex = 3;
            else if (__rand__ < 0.97) winIndex = 5;
            else if (__rand__ < 0.99) winIndex = 7;
            else {
                winIndex = 0;
            }
            return winIndex;
        },
        showModal() {
            this.visible = true;
        },
        hideModal() {
            this.visible = false;
        },
    },

    computed: {},
};
</script>
 
 
<style  scoped lang="scss">
.dial {
    background: #5da9e5 url(../assets/happy51/bg.png) 100% no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    min-height: 1250px;
    padding-top: 200px;
}
.times {
    text-align: center;
    line-height: 0.8rem;
    background: #fff;
}
.rotate {
    width: 300px;
    height: 300px;
    background: #ffbe04;

    margin: 18px auto;
    //transform: translate(-50%, -50%);
    //border:5px solid #000;
    position: relative;
    border-radius: 50%;
    overflow: visible;
    border: 0px solid blue;
    z-index: 0;
    border: 0px solid red;
}

.rotate .dish {
    width: 270px;
    height: 270px;
    //border-radius: 135px;
    //display: none;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
}

.pointer {
    width: 86px;
    position: absolute;
    left: 110px;
    bottom: 108px;
}

/* 圆点 */
.rotate .circle {
    position: absolute;
    display: block;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    background: black;
}

.rotate .circle_0 {
    left: 290px;
    top: 145px;
    background: #000;
}

.rotate .circle_1 {
    left: 279px;
    top: 87px;
    background: blue;
}

.rotate .circle_2 {
    left: 246px;
    top: 41px;
}

.rotate .circle_3 {
    left: 204px;
    top: 12px;
}

.rotate .circle_4 {
    left: 150px;
    top: 0;
}

.rotate .circle_5 {
    left: 93px;
    top: 11px;
}
.rotate .circle_6 {
    left: 44px;
    top: 44px;
}
.rotate .circle_7 {
    left: 11px;
    top: 93px;
}
.rotate .circle_8 {
    left: 0px;
    top: 150px;
}
.rotate .circle_9 {
    left: 13px;
    top: 207px;
}
.rotate .circle_10 {
    left: 50px;
    top: 252px;
}
.rotate .circle_11 {
    left: 89px;
    top: 280px;
}
.rotate .circle_12 {
    left: 140px;
    top: 290px;
}
.rotate .circle_13 {
    left: 197px;
    top: 279px;
}
.rotate .circle_14 {
    left: 246px;
    top: 246px;
}
.rotate .circle_15 {
    left: 279px;
    top: 197px;
}

.bg {
    width: 375px;
    position: absolute;
    z-index: -10;
}
.goodMsg {
    font-size: 18px;
    text-align: left;
    color: red;
    border:0px solid red;
    position:relative;
    left:375px;

    animation:move 6s infinite linear;
}
@keyframes move{
    0%{ left:375px}
    100%{left:-200px}
}
.title {
   
}
.title_img{width:360px;margin:0 auto}
.word {
    width: 370px;
    margin: 30px auto;
    font-size: 16px;
    color: #fff;
    text-align: left;
    line-height: 160%;
}
.word .wordtitle {
    text-align: center;
    margin-bottom: 0px;
    color: red;
    font-size: 16px;
    text-shadow: 2px 2px 0 #f3c815;
}
.word div:not(.wordtitle) {
    text-align: center;
    font-size: 14px;
}
.word span {
    text-decoration: underline;
    text-underline-offset: 8px;
}

.btnWrapper {
    width: 340px;
    margin: 0 auto;
    border: 0px solid red;
    display: flex;
    justify-content: space-around;
}
.btn {
    width: 125px;
    height: 48px;
    background-image: linear-gradient(#f3c815, #f98b03);
    color: #fff;
    border: 5px solid #fff;
    border-radius: 22px;
    text-align: center;
    font-size: 15px;
    line-height: 110%;
}

.btn1 {
    line-height: 40px;
     animation:btncarton 3s infinite linear ;
}
.btn2 {
    padding-top: 3px;
     animation:btncarton 3s infinite linear ;
}
@keyframes btncarton{
    0%{transform:scale(0.8,0.8)}
    100%{transform:scale(1,1)}
}
.desc {
    padding-top: 10px;
    line-height: 150%;
    font-size: 10px;
}
.mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: rgba(0, 0, 0, 0.6);
}
.res1_img {
    width: 375px;
    display: block;
    position: absolute;
    top: 20px;
    border: 0px solid red;
}
.share_img {
    width: 100px;
    display: block;
    position: absolute;
    top: 10px;
    right:10px;
    border: 0px solid red;
}
.close {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    color: yellow;
    border: 2px solid yellow;
    text-align: center;
    line-height: 30px;
    font-size: 30px;
    position: absolute;
    right: 30px;
    top: 70px;
}
.ma_img {
    width: 115px;
    height: 108px;
    position: absolute;
    top: 245px;
    left: 130px;
}
.result1 {
    position: absolute;
    top: 360px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    border: 0px solid red;
    left: 0;
    right: 0;
    margin: auto;
}
.result1.result2 {
   top:180px
}
.result1 div:nth-child(1) {
    color: #210302;
    font-size: 18px;
    font-weight: bold;
    margin-top: 8px;
}
.result1 div:nth-child(2) {
    color: #ff5137;
    font-size: 16px;
    font-weight: bold;
    margin-top: 4px;
}
.result1 div:nth-child(3) {
    color: #b8311c;
    font-size: 14px;
    font-weight: bold;
    margin-top: 4px;
}
.share_btn1 {
    background-image: linear-gradient(#f3c815, red);
    border: 3px solid #fff;
    height: 50px;
    width: 150px;
    border-radius: 16px;
    color: #fff;
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    position: absolute;
    top: 520px;
    left: 100px;
    padding-top: 7px;
}
.share_btn1.share_btn2{top:390px}
</style>