<template>
  <div class="wrapper">

     <div class="box"></div>
  </div>
</template>

<script>
export default {
  props: {
  },
  data () {
    return {
    }
  },
  created () {},
  mounted () {},
  watch: {},
  computed: {},
  methods: {},
  components: {},
}
</script>
<style scoped>
.wrapper{padding-top:20px}
.box{width:150px;height:150px;background-image: linear-gradient(red,yellow);margin:20px auto;transition:all 3s linear}
.box:hover{transform: rotate(60deg);}
</style>