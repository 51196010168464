<template>
    <div class="wrapper">
        <div class="container">
            <img src="../assets/app/addoil.jpg" alt="" class="banner">
            <div class="header">
                <span class="iconfont icon-hezuo" style="color:#4461E9"></span> 合作加油站
            </div>

            <div class="item">
                <div class="item_name"><span class="iconfont icon-jiayouqia" style="color:#FF7F00"></span>南京海途水上
                    服务区加油站</div>
                <div class="item_address">长江南京段#126-#127黑浮联线北侧</div>
            </div>
            <div class="item">
                <div class="item_name"><span class="iconfont icon-jiayouqia" style="color:#FF7F00"></span>海途芜湖水上服务区加油站</div>
                <div class="item_address">长江芜湖段195#浮下游600米</div>
            </div>
            <div class="item">
                <div class="item_name"><span class="iconfont icon-jiayouqia" style="color:#FF7F00"></span>龙潭兴隆洲加油</div>
                <div class="item_address">南京长江段龙潭水道129号浮上游200米</div>
            </div>
            <div class="item">
                <div class="item_name"><span class="iconfont icon-jiayouqia" style="color:#FF7F00"></span>龙潭乌鱼洲加油站</div>
                <div class="item_address">南京长江段龙潭水道129号浮上游300米</div>
            </div>
            <div class="pad"></div>

            
        </div>
        <div class="footer">
                <div class="btn" @click="call"><a-icon type="phone" class="phone" /> 加油服务热线</div>   
        </div>

    </div>
</template>

<script>
export default {
    props: {},
    data() {
        return {};
    },
    created() {},
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        call(){
            window.location.href='tel:13813866848'
        }
    },
    components: {},
};
</script>
<style scoped>
@import "../css/iconfont/iconfont.css"; /*这个分号一定要写，要不会报错*/
.wrapper {
    background: #f3f3f3;
}
.container {
    width: 350px;
    border: 0px solid red;
    margin: 0 auto;
    height: 200px;
    font-size: 12px;
    padding-top:15px
}
.banner {
    width: 100%;
    border-radius: 4px;
}
.header {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    background: #fff;
    margin-top: 15px;
    border-radius: 4px;
    padding-left: 10px;
}

.item {
    background: #fff;
    height: 90px;
    margin-top: 15px;
    padding-left: 10px;
}
.item .item_name {
    color: #4461e9;
    font-size: 15px;
    height: 45px;
    line-height: 60px;
}
.item .item_address {
    font-size: 14px;
    height: 20px;
    line-height: 18px;
    padding-left: 2px;
}
.pad{height:70px}
.footer{background:#fff;height:65px;width:100%;position:fixed;left:0;bottom:0;border-top-left-radius:16px;border-top-right-radius:16px;}
.btn{width:350px;height:42px;line-height:42px;text-align: center;font-size:16px;color:#fff;background:#4461e9;border-radius:6px;top:6px;left:12.5px;position:absolute;}
</style>