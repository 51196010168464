<template>
    <div class="about">
        <div>
            <img src="../assets/about/about_header.jpg" alt="" />
        </div>

        <p style="padding-top: 18px; line-height: 150%">
            江苏好三由信息科技有限公司是一家以船货匹配业务为主导，围绕船舶产业链系列业务为配套，集整合内河、沿海航运、公路全产业供应链信息为一体的科技服务大数据平台企业。
        </p>
        <p style="line-height: 150%">
            公司通过构建“航运+数智+安全”的智慧航运交易平台，规范内河航运业，整合货运信息，提升船货匹配效率，并在此基础上为货主船东提供增值服务，为政府规范归集内河航运税收。公司致力于成为航运数字化生态链的先驱者，打造长江航运一体化结算中心。
        </p>
        <p style="line-height: 150%">
            2018年，好三由通过自主创新，研发上线了“好三由智慧航运平台”，经历了两年技术和服务的积淀，“好三由智慧航运平台”具有领先的创新优势和前瞻的合规生态洞察。
        </p>
        <p style="line-height: 150%">
            平台基于行业大数据，依托AIS船位追踪等领先技术，提供“船货智能匹配、货物运输保险、运费资金线上结算、船舶追踪”等相关系统功能，集信息流、资金流、票据流于一体，具备物流信息全流程跟踪、记录、存储、分析能力，充分保障物流交易真实性，推动行业向标准化、可视化、数字化、智能化发展，以科技创新为航运行业带来了新的发展动能。
        </p>
        <div class="bossZone">
            <img src="../assets/about/boss.png" class="boss" />
            <img src="../assets/about/boss_text.png" class="boss_text" />
            <p class="words">
                出身于船民世家，深知航运业痛点，投身船运+互联网平台建设，始终坚定步伐，牢记使命，不断探索总结提高，构建全新的数字航运生态圈，只为让船民过上更好的生活。
            </p>
            <img src="../assets/about/sign.png" class="sign" />
        </div>
        <div class="branchLine first">
            <div class="companyInfo">
                <h2>江苏好三由信息科技有限公司</h2>
                <div style="height: 1px; background: #fff"></div>
                <p>地址：南京雨花台区软件大道170号天溯科技园4栋</p>
                <p @click="makeCall" data-tel="02583766081">
                    电话：025－83766081
                </p>
                <p>邮箱：service@hao3you.com</p>
                <p>邮编：210000</p>
                <div class="headoffice">
                    <div></div>
                    <p>南京总部</p>
                </div>
            </div>
            <div class="companyInfo">
                <h2>浙江自贸区好三由信息科技有限公司</h2>
                <div style="height: 1px; background: #fff"></div>
                <p>地址：浙江舟山市普陀区东港财富中心B座10楼</p>
                <p @click="makeCall" data-tel="05803263806">
                    电话：0580－3263806
                </p>
                <p>邮箱：service@hao3you.com</p>
                <p>邮编：316000</p>
            </div>
        </div>
        <div class="branchLine">
            <div class="companyInfo">
                <h2>南通好三由智慧物流有限公司</h2>
                <div style="height: 1px; background: #fff"></div>
                <p>地址：江苏南通通州区江海智汇园A1幢351室</p>
                <p @click="makeCall" data-tel="18757570240">
                    电话：18757570240
                </p>
                <p>邮箱：service@hao3you.com</p>
                <p>邮编：226000</p>
            </div>
            <div class="companyInfo">
                <h2>江苏好三由信息科技有限公司郑州分司</h2>
                <div style="height: 1px; background: #fff"></div>
                <p>地址：河南新郑市郭店镇传化物流小镇2号2301-1室</p>
                <p @click="makeCall" data-tel="13770959251">
                    电话：18757570240
                </p>
                <p>邮箱：service@hao3you.com</p>
                <p>邮编：450000</p>
            </div>
        </div>
        <div class="branchLine">
            <div class="companyInfo">
                <h2>安徽中柯智慧物流有限公司</h2>
                <div style="height: 1px; background: #fff"></div>
                <p>地址：安徽省安庆市迎江区紫峰产业大厦B座3006室</p>
                <p @click="makeCall" data-tel="18757570240">
                    电话：18757570240
                </p>
                <p>邮箱：service@hao3you.com</p>
                <p>邮编：246001</p>
            </div>
            <div class="companyInfo">
                <h2>天津好三由物流科技有限公司</h2>
                <div style="height:1px;background:#fff"></div>
                <p>地址：天津市东疆保税港区鄂尔多斯路599号，天津港东疆商务中心项目A3座501室</p>
                <p @click="makeCall" data-tel="18757570240">电话：18757570240</p>
                <p>邮箱：service@hao3you.com</p>
                <p>邮编：300456</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    mounted(){
    },
    methods: {
        makeCall(e) {
            let tel = e.currentTarget.dataset.tel;
            window.location.href = "tel:" + tel;
        },
    },
};
</script>
<style scoped>
img {
    display: block;
    width: 100%;
}
div {
    font-size: 14px;
}
p {
    padding: 0 18px;
}
.bossZone {
    height: 260px;
    position: relative;
    width: 100%;
    background: #000;
    margin-top: 80px;
}
.bossZone .boss {
    position: absolute;
    width: 242px;
    right: 0;
    bottom: 0;
}
.bossZone .boss_text {
    position: absolute;
    width: 150px;
    left: 20px;
    top: 20px;
}
.bossZone .words {
    color: #fff;
    width: 180px;
    padding-top: 50px;
    text-justify: justify;
    font-size: 10px;
    line-height: 150%;
}
.bossZone .sign {
    display: block;
    position: absolute;
    bottom: 30px;
    left: 50px;
    width: 100px;
}

.branchLine {
    font-size: 12px;
    width: 350px;
    height: 100px;
    background: rgba(122, 150, 180, 0.1);
    margin: 10px auto;
}
.branchLine.first {
}
.branchLine .companyInfo {
    width: 168px;
    float: left;
    height: 100px;
    margin: 0 3px;
    position: relative;
}
.companyInfo h2 {
    font-size: 9px;
    border-bottom: 1px solid #e1e1e1;
    line-height: 31px;
    height: 27px;
    font-weight: 600;
    margin: 0;
    padding: 0;
}
.companyInfo > p {
    font-size: 8px;
    line-height: 12px;
    height: 12px;
    padding: 0;
    height: 16px;
    margin: 0;
}
.companyInfo p:first-of-type {
    margin-top: 3px;
    height: 21px;
}
.headoffice {
    position: absolute;
    top: 5px;
    right: 2px;
    width: 45px;
    height: 18px;
    overflow: hidden;
}
.headoffice > p {
    background: #ccc;
    position: absolute;
    width: 38px;
    height: 12px;
    background: #f00;
    line-height: 16px;
    text-align: center;
    top: 0px;
    color: #fff;
    right: 0;
    font-size: 8px;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
.headoffice div {
    border-style: solid;
    border-color: transparent #f00 transparent transparent;
    border-right-width: 4px;
    border-top-width: 2px;
    position: absolute;
    right: 38px;
    top: 6px;
    z-index: 10000;
    font-weight: bold;
}
</style>
