<template>
    <div class="wrapper">
        <img src="../assets/new/ma.png" alt="" class="ma">
        <img src="../assets/new/huodong.png" alt="" class="huodong">
        <div class="desc">
            <div class="line line1">
                <div class="circle">1</div>上传照片赢女神大奖】
            </div>
            <div class="line line2">
                <div class="circle">2</div>下载好三由APP,连续签到7日更有女神关怀大礼包
            </div>

        </div>
        <div class="sign">
            <div class="sign_num">
                <div class="sign_num_item">
                    <div>35</div>
                    <div>已报人数</div>
                </div>
                <div class="sign_num_item">
                    <div>2258</div>
                    <div>投票数</div>
                </div>
                <div class="sign_num_item">
                    <div>12548</div>
                    <div>浏览量</div>
                </div>
            </div>
            <div class="sign_btn" @click="join">我要报名参加活动</div>
            <div class="left_time"><span>活动结束时间还有</span><span>7天12小时12分56秒</span></div>
        </div>
        <div class="tab">
            <div class="tab_item" :class="{'current':tabCurrent==1}" @click="tabClick(1)">
                <p>投票活动</p>
            </div>
            <div class="tab_item" :class="{'current':tabCurrent==2}" @click="tabClick(2)">
                <p>报名统计</p>
            </div>
            <div class="tab_item" :class="{'current':tabCurrent==3}" @click="tabClick(3)">
                <p>活动说明</p>
            </div>
        </div>
        <div class="content content1" v-if="tabCurrent==1">
            <img src="../assets/new/title2.png" alt="" class="title">
            <div class="item_wrapper">
                <div class="item" v-for="(item,index) in picList" :key="index">
                    <img src="../assets/new/pic.jpg" />
                    <div class="num" v-if="index<10">0{{index+1}}</div>
                    <div class="num" v-else>{{index+1}}</div>
                    <div class="word">新年寄语</div>
                    <div class="word_content">祝大家新年时刻开开心心！</div>
                    <div class="btn_wrapper">
                        <div class="btn btn1" @click="vote">投票</div>
                        <div class="btn btn2">拉票</div>
                        <div class="btn btn3">6624<span>票</span></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content content2" v-if="tabCurrent==2">
            <img src="../assets/new/top_15.jpg" alt="" class="title">
            <div class="row" v-for="(item,index) in rowList" :key="index">
                <div class="circle">{{item.id}}</div>
                <div class="idtext">{{item.id}}号</div>
                <div class="line"></div>
                <div class="name">{{item.name}}</div>
                <div class="vote_num">{{item.num}}票</div>
            </div>
        </div>

        <div class="content content3" v-if="tabCurrent==3">
            <div class="row_title">活动介绍</div>
            <div>送别萌虎，迎接福兔。好三由为答谢广大船民兄弟的支持与信任，特此元旦佳节之际为船民兄弟举办，福兔迎新、趣享元旦的活动，欢迎大家踊跃报名参加</div>

            <div class="row_title">活动参与规则：</div>
            <div>
                1，参与活动的船民朋友上传新年寄语和您的全家福照片到好三由元旦活动页面。<br />
                2，投稿通道：好三由元旦活动参与链接，点开后点击我要参与活动上传活动内容即可。<br />
                3，投稿完成后即可参与到投票环节，活动截止前都可参与投票，诚信投票，禁止网络刷票
                每人每日可投5票。<br />
                4，活动时间2023年1月1日-2023年1月5日。<br />
                5，获奖名单公布时间：1月6日，公布渠道好三由微信公众号和好三由各平台视频官方账号<br />
                6，参与者应保证所发布的文字内容或者视频内容已征得本人同意。如出现版权问题，有相关参与者承担相关法律责任。<br />
                7，本次活动最终解释权归好三由所有。<br />
            </div>

            <div class="row_title">奖品设置：</div>
            <div>
                一等奖 1名 奖品：好三由兔年礼盒、好三由精美台历、高级电热毯、品牌暖风机<br />
                二等奖3名 奖品：好三由兔年礼盒、好三由精美台历、品牌暖风机<br />
                三等奖5名 奖品：好三由兔年礼盒、好三由精美台历、冬季保暖防水手套<br />
                参与奖若干 奖品：好三由兔年礼盒、精品定制水杯<br />
            </div>
        </div>

        <div class="mask" v-if="maskShow">
            <div class="login_zone">
                <div class="login_title">登录</div>
                <div class="close" @click="close">&times;</div>

                <a-input placeholder="请输入手机号" v-model="tel" class="input" />
                <a-input placeholder="请输入验证码" v-model="code" class="input">
                    <div slot="addonAfter" @click="getCode">获取短信验证码</div>
                </a-input>
                <a-input placeholder="请输入真实姓名" v-model="name" class="input" />
                  <div style="height:12px"></div>
                <a-upload action="https://www.mocky.io/v2/5cc8019d300000980a055e76" list-type="picture" :default-file-list="fileList" class="input" style="width:100%">
                    <a-button style="width:100%">
                        <a-icon type="plus" /> 上传全家福照片(可重复上传)
                    </a-button>
                </a-upload> 
                 <div class="submit_btn">确定</div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {},
    data() {
        return {
            tabCurrent: 1,
            picList: [
                { id: 1 },
                { id: 2 },
                { id: 3 },
                { id: 4 },
                { id: 5 },
                { id: 6 },
            ],
            rowList: [
                { id: 1, name: "张大明", num: 12 },
                { id: 2, name: "张大明", num: 8526 },
                { id: 3, name: "张大明", num: 575551 },
                { id: 4, name: "张大明", num: 1238 },
                { id: 5, name: "张大明", num: 2593 },
            ],
            tel: "",
            code: "",
            name: "",
            fileList: [
                {
                    uid: "-1",
                    //name: "xxx.png",
                    name:"",
                    status: "done",
                    url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
                    thumbUrl:
                        "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
                },
               
            ],
            maskShow:false
        };
    },
    created() {},
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        tabClick(num) {
            if (num == this.tabCurrent) {
                return;
            } else {
                this.tabCurrent = num;
            }
        },
        getCode() {
            console.log("code");
        },
        close(){
            this.maskShow = false;
        },
        vote(){
            //点击投票
            console.log("click")
            let _current = new Date().getTime();
            let _last = localStorage.getItem("voteTime")||0;
            if(_current-_last<24*60*60*1000){
                this.$message.error("一天只能投一次")
            }else{
                localStorage.setItem("voteTime",_current)
            }
        },
        join(){
            this.maskShow = true;
        }
    },
    components: {},
};
</script>
<style scoped>
.wrapper {
    width: 100%;
    min-height: 1000px;
    background:#F9C7C8 url(../assets/new/bg.png) no-repeat;
    background-size: 100% auto;
    font-size: 12px;
    padding: 14px 14px;
    padding-top: 230px;
    color: #000;
}
.sign {
    width: 350px;
    height: 195px;
    background: url(../assets/new/bg2.png) no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    border: 1px solid transparent;
    position: relative;
    padding: 0 55px;
    
}
.sign_num {
    display: flex;
    justify-content: space-between;
    border: 0px solid red;
    text-align: center;
    margin-top: 44px
}
.sign_num_item div:nth-child(1) {
    font-size: 18px;
    font-weight: bold;
    color: #ff5015;
}
.sign_num_item div:nth-child(2) {
    margin-top:5px
}
.sign_btn {
    width: 100%;
    height: 36px;
    border-radius: 4px;
    background-image: linear-gradient(#F8D1D4, #DB4547);
    line-height: 36px;
    text-align: center;
    color: #fff;
    margin-top: 14px;
    font-size: 16px;
}
.left_time {
    text-align: center;
    padding-top: 8px;
    line-height:111%!important;
}
.left_time > span {
    display: block;
}
.left_time > span:nth-child(1) {
    color: #777;
}
.left_time > span:nth-child(2) {
    color: #000;
    margin-top:8px;
}
.tab {
    height: 40px;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.tab_item {
    width: 30%;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border-radius: 8px;
    color: #777;
    background: #fff;
    font-size: 16px;
}
.tab_item.current {
    background-image: linear-gradient(#ff8d89 0, #fff 70%);
    color: #ff5015;
}
.tab_item.current p {
    border-bottom: 2px solid #ff5015;
    line-height: 20px;
    width: 66%;
    margin: 8px auto;
}
.content {
    width: 100%;
    height: auto;
    background: #fff;
    border-radius: 8px;
    margin: 19px auto;
    padding: 15px;
}
.content .title {
    width: 100%;
    display: block;
    border: 0px solid red;
}
.content1 {
    overflow: hidden;
}
.item_wrapper {
    display: flex;
    border: 0px solid #000;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 15px;
}
.item_wrapper .item {
    width: 48%;
    margin-bottom: 28px;
    position: relative;
}
.item_wrapper .item img {
    width: 98%;
    display: block;
}
.item_wrapper .item .num {
    width: 19px;
    height: 19px;
    font-size: 12px;
    text-align: center;
    line-height: 22px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 5px;
    top: 76px;
    color: #ff5015;
    font-weight: bold;
}
.btn_wrapper {
    display: flex;
    justify-content: space-between;
}
.item_wrapper .item .word {
    text-align: center;
    color: #ff5015;
    height: 24px;
    line-height: 27px;
    font-size: 14px;
}
.btn_wrapper {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
}
.btn_wrapper .btn {
    height: 24px;
    text-align: center;
    line-height: 24px;
    border-radius: 6px;
    font-size: 12px;
    padding: 0 6px;
}
.btn_wrapper .btn1 {
    color: #fff;
    background: #f12326;
    border: 1px solid #f12326;
}
.btn_wrapper .btn2 {
    color: #ff8105;
    border: 1px solid #ff8105;
}
.btn_wrapper .btn3 {
    color: #00d9a3;
    border: 1px solid #00d9a3;
}
.btn_wrapper .btn3 span {
    color: #999;
}

.row {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #ccc;
    position: relative;
    line-height: 48px;
    color: #000;
    margin-top: 15px;
}
.row .circle {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    text-align: center;
    line-height: 26px;
    color: #ff5015;
    font-size: 16px;
    font-weight: bold;
    background: #f3f3f3;
    margin-top: 11px;
    float: left;
    margin-left: 9px;
}
.row .idtext {
    font-size: 14px;
    float: left;
    margin-left: 20px;
}
.row .line {
    width: 2px;
    height: 14px;
    background: #333;
    float: left;
    margin-top: 17px;
    margin-left: 4px;
}
.row .name {
    margin-left: 5px;
    float: left;
    font-size: 14px;
}
.row .vote_num {
    color: #f12326;
    float: right;
    margin-right: 20px;
    font-weight: bold;
}
.row_title {
    font-size: 16px;
    padding: 13px;
    padding-left: 0;
}
.content3 {
    font-size: 14px;
    line-height:150%
}
.mask {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
}
.mask .login_zone {
    width: 320px;
    position: absolute;
    left: 23px;
    top: 5%;
    height:auto;
    background: #fff;
    border-radius: 10px;
    font-size: 14px;
    padding: 15px;
}
.login_zone .login_title {
    line-height: 30px;
    font-size: 16px;
}
.login_zone .close {
    padding: 12px 20px;
    border: 0px solid red;
    display: inline;
    font-size: 24px;
    position: absolute;
    top: 0;
    right: 0;
}
.login_zone .input {
    margin-top: 10px;
}

.input>>>.ant-upload{width:100%}
.submit_btn{width:100%;height:40px;text-align:center;text-align:center;line-height:42px;color:#fff;font-size:18px;background-image:linear-gradient(#FF9326,#FF0B03);border-radius: 8px;margin-top: 12px;}
.ma{display:block;width:64%;margin:0 auto}
.huodong{display:block;width:64%;margin:0 auto;margin-top:15px}
.desc{width:320px;margin:10px auto;color:red;font-size:14px;line-height:180%}
.desc .circle{width:14px;height:14px;background:#fff;display:inline-block;text-align:center;line-height:12px;border-radius:50%}
</style>