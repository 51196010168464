<template>
    <div class="wrapper">
        <div class="about">
            <img src="../assets/logo.png" class="logo" />
            <div class="version">
                版本：{{version}}
            </div>
            <div class="info">
                <p>
                    江苏好三由信息科技有限公司是一家以船货匹配业务为主导，围绕船舶产业链系列业务为配套，集整合内河、沿海航运、公路全产业供应链信息为一体的科技服务大数据平台企业。
                </p>
                <p>
                    公司通过构建“航运+数智+安全”的智慧航运交易平台，规范内河航运业，整合货运信息，提升船货匹配效率，并在此基础上为货主船东提供增值服务，为政府规范归集内河航运税收。公司致力于成为航运数字化生态链的先驱者，打造长江航运一体化结算中心。
                </p>
                <p>2018年，好三由通过自主创新，研发上线了“好三由智慧航运平台”，经历了两年技术和服务的积淀，“好三由智慧航运平台”具有领先的创新优势和前瞻的合规生态洞察。</p>
                <p>平台基于行业大数据，依托AIS船位追踪等领先技术，提供“船货智能匹配、货物运输保险、运费资金线上结算、船舶追踪”等相关系统功能，集信息流、资金流、票据流于一体，具备物流信息全流程跟踪、记录、存储、分析能力，充分保障物流交易真实性，推动行业向标准化、可视化、数字化、智能化发展，以科技创新为航运行业带来了新的发展动能。
                </p>
            </div>
            <div class="copyRight">
                <p>
                    好三由 版权所有<br/>
                
                    Copyright © Haosanyou. All Rights Reserved.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {},
    data() {
        return {
            version:""
        };
    },
    created() {},
    mounted() {
        console.log(this.$route)
        this.version = this.$route.query.version;
    },
    watch: {},
    computed: {},
    methods: {},
    components: {},
};
</script>
<style scoped>
body,
html {
    background: #f6f6f6;


}
.logo{width:80px;height:80px;margin:00px auto;display:block;}
.about {
    padding: 20px 8px;
    font-size:13px;
}
.version {
    text-align:center;
    padding:10px 
}
.info {
  padding:0 6px;
    border: 0px solid red;
}
.info p {
     text-align:justify;
    text-justify:inter-word;
    line-height:180%;
}
.copyRight p{height:20px;border:0px solid red;font-size:13px}
.copyRight{text-align:center;line-height:20px;color:#555;padding-top:20px}

</style>