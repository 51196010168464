<template>
    <div class="bannerWrapper">
        <div class="banner" v-if="bannerId == 1">
            <div class="content">
                <table></table>
                 <img src="../assets/insurance/pic1.png" alt="" class="pic1">
                <div class="top_tit">
                    <img src="../assets/insurance/dun.png" alt="" class="dun">
                    <div class="top_tit2">
                        <img src="../assets/insurance/jiahao.png" alt="" class="jiahao">
                          好三由与中国人民保险公司建立了长期稳定的合作关系，针对散装货物、谷粮类货物，为广大货主提供可靠的货运险服务。我们利用大数据管控风险，为船东货主提供最优质险种组合搭配方案；通过组建业务、操作、理赔三重专业团队。
                    </div>
                </div>

                <div class="client">
                    <div class="client_tit">
                       <div class="circle"></div>
                       <div>为客户提供</div>
                       <div class="circle"></div>
                    </div>

                    <div class="client_item">
                        <div class="client_child">
                            <div><img src="../assets/insurance/icon1.png" alt="" class="icon_img"></div>
                            <div class="client_text">
                                <h2>专业咨询</h2>
                                <h5>专业客服介绍</h5>
                            </div>
                        </div>
                        <div class="client_child">
                            <div><img src="../assets/insurance/icon2.png" alt="" class="icon_img"></div>
                            <div class="client_text">
                                <h2>合理报价</h2>
                                <h5>更少钱更多保障</h5>
                            </div>
                        </div>
                        <div class="client_child">
                            <div><img src="../assets/insurance/icon3.png" alt="" class="icon_img"></div>
                            <div class="client_text">
                                <h2>快速出单</h2>
                                <h5>更快更方便</h5>
                            </div>
                        </div>
                        <div class="client_child">
                            <div><img src="../assets/insurance/icon4.png" alt="" class="icon_img"></div>
                            <div class="client_text">
                                <h2>及时理赔</h2>
                                <h5>理赔更加方便</h5>
                            </div>
                        </div>       
                    </div>

                    <p class="client_text2">专业咨询、合理报价、快速出单、及时索赔等专业航运保险服务，解决当前市场上保险配套服务能力薄弱、保险选择困难等痛点。</p>
                </div>
             <!-- 01 02 03 04 -->
                <div class="introduce">
                      <div class="intro_child" style="padding-bottom:0">
                             <div class="intro_child1">
                                 <div class="num">
                                     <img src="../assets/insurance/index01.png" alt="">
                                 </div>
                                 <div class="intro_text">
                                     <h2>承保物资更精确，费率更低</h2>
                                     <p>承保货物类型包括钢材、煤炭、铁矿石和谷粮类，费率低至0.13‰起。</p>
                                 </div>
                             </div> 

                              <div class="intro_child1">
                                 <div class="num">
                                     <img src="../assets/insurance/index02.png" alt="">
                                 </div>
                                 <div class="intro_text">
                                     <h2>保额无限制，免赔额2000元起</h2>
                                     <p>钢铁、煤炭、铁矿石等大宗商品——投保金额无限制，每次事故绝对免赔额为2000元，或损失金额的10%，以高为准。</p>
                                     <p>粮食类——每次事故绝对免赔额为4000元或保险金额的0.30%，两者取高。</p>
                                 </div>
                             </div>

                              <div class="intro_child1">
                                 <div class="num">
                                     <img src="../assets/insurance/index03.png" alt="">
                                 </div>
                                 <div class="intro_text">
                                     <h2>理赔更容易</h2>
                                     <p>保障船龄：25年以内（不含25），设置理赔快速通道，关键港口2小时到达，专业售后服务保障，专人一对一服务！</p>
                                 </div>
                             </div>

                              <div class="intro_child1">
                                 <div class="num">
                                     <img src="../assets/insurance/index04.png" alt="">
                                 </div>
                                 <div class="intro_text">
                                     <h2>投保更快，可开发票</h2>
                                     <p>7*24小时在线投保，在线支付后即时生成保单。投保人/被保险人均可开发票，累积金额达5000可开专票。</p>
                                 </div>
                             </div>
                             
                      </div>
                </div>
            <!-- bottom -->
                 <div class="foot">保险专属客服：<a style="text-decoration: none;color: white;" href="tel:15312027044">15312027044</a></div>
            </div>
            
          
        </div>
        <div class="banner2" v-if="bannerId == 2">
             <div class="content2">
                 <table></table>
                 <div class="chuan_content">
                     <h2>让船民过上更好的生活</h2>
                     <p>
                         好三由自2018年创立伊始，就致力于“让船民过上更好的生活”
                        在日新月异的今天，为推进传统航运向科技、智能、合规化而不断努力。
                     </p>
                     <h2 style="width:150px">为船民解决难题</h2>
                     <p>为了解决内河航运船民长期以来的，找货难、价格不透明、信誉信息真假难辨等问题
                        我们推出了好三由船东版APP，从入驻端严格审核货主资质和相关证件，同时积极推进与各大货主公司的全权合作，确保平台优先发布一手货源。</p>
                     <h2 style="width:180px">好三由船东版APP可:</h2>
                     <h5 style="margin-top:15px">根据船舶路径优先匹配附近货源；</h5>
                     <h5>可视化交易实时监督交易进程平台匹配货源；</h5>
                     <h5>货款到账及时准确；</h5>
                     <h5>船东可自行从后台查询每一笔交易账单；</h5>
                     <h5>提现秒级到账！</h5>
                 </div>
                 <div style="height:20px"></div>
             </div>
           
           
          
        </div>
        <!-- <div class="banner3" v-if="bannerId == 3">
            <div class="infoItem infoItem1">
                <h2 class="bigTitle">保费更低，理赔更快</h2>
                <p style="margin-top: -35px">
                    好三由与中国人民保险公司深入合作，为广大船民推出个性化定制的船民险服务。船民险服务于好三由智慧航运平台注册船员，保费更低、理赔更快，为您提供可靠的保险服务方案。
                    为了便捷船民，投保只需填写问卷。符合要求即可投保，请如实填写哦~
                </p>
                <div class="titleZone">
                    <div class="infoTitle">团体意外险：</div>
                    <div class="infoDesc">保费850元/人，最高可赔30万</div>
                </div>
                <div class="insurance">
                    <h2>保险范围</h2>
                    <p>
                        人员：好三由智慧航运平台注册船员 <br />
                        年龄：16-65周岁 <br />
                        保险期限：1年<br />
                        司法管辖：中华人民共和国司法（港澳台除外） <br />
                    </p>
                </div>
                <table>
                    <tr>
                        <th>项目</th>
                        <th>金额</th>
                    </tr>
                    <tr>
                        <td>意外身故、残疾</td>
                        <td>30万</td>
                    </tr>
                    <tr>
                        <td>意外医疗</td>
                        <td>3万</td>
                    </tr>
                    <tr>
                        <td>免赔</td>
                        <td>500元</td>
                    </tr>
                    <tr>
                        <td>赔付比例</td>
                        <td>100%</td>
                    </tr>
                    <tr>
                        <td>保费</td>
                        <td>850元/人</td>
                    </tr>
                </table>
            </div>
            <div class="infoItem infoItem2">
                <div class="titleZone" style="margin-top: -20px">
                    <div class="infoTitle">重大疾病保险：</div>
                    <div class="infoDesc">
                        保费低至33元起，保额一次性给付，最高20万元
                    </div>
                </div>
                <div class="insurance">
                    <h2>保险范围</h2>
                    <p>
                        人员：好三由智慧航运平台注册船员<br />
                        年龄：0-100周岁<br />
                        保险期限：1年<br />
                        司法管辖：中华人民共和国司法（港澳台除外）<br />
                    </p>
                </div>
            </div>
            <div class="infoItem infoItem2">
                <div class="titleZone" style="margin-top: -20px">
                    <div class="infoTitle">大病医疗险（家庭版）：</div>
                    <div class="infoDesc" style="text-align: left">
                        2-4人家庭，有社保保费低至136元起，无社保保费低至236元起，共享免赔额1万元，共享保额300万元。2-7人家庭有社保保费低至86元起，无社保保费低至146元起，共享免赔额2万元，共享保额300万元
                    </div>
                </div>
                <div class="insurance">
                    <h2>保险范围</h2>
                    <p>
                        人员：好三由智慧航运平台注册船员<br />
                        年龄：0-60周岁，60-80岁仅针对于与续保客户<br />
                        医院范围：境内二级或二级以上公立医院或保险人认可的医疗机构的普通部
                        <br />
                        保险期限：1年<br />
                        司法管辖：中华人民共和国司法（港澳台除外）<br />
                    </p>
                </div>
            </div>
            <div class="tel">
                <a href="tel:15312027044"
                    ><img src="../assets/banners/tel2.png"
                /></a>
            </div>
        </div> -->
        <div class="banner4" v-if="bannerId == 4">
             <table></table>
            <div class="jifen">
                <div class="ji">
                    <div class="line"></div>
                    <div class="jifen_tit">积分兑换</div>
                    <div class="line"></div>
                </div>

                <div class="product">
                     <div class="pro_child">
                          <div class="pro_pic"><img src="../assets/jifen/jifen1.png" style="width:40px;margin-top:6px"></div>
                          <div>
                              <p class="pro_p1">防潮防霉包<br>10包</p>
                              <p class="pro_p2">400积分</p>
                          </div>
                     </div>
                     <div class="pro_child">
                         <div class="pro_pic"><img src="../assets/jifen/jifen2.png" style="margin-top:24px"></div>
                          <div>
                              <p class="pro_p1">10元话费</p>
                              <p class="pro_p2">400积分</p>   
                          </div>
                     </div>
                     <div class="pro_child">
                          <div class="pro_pic"><img src="../assets/jifen/jifen3.png" style="width:56px;margin-top:14px"></div>
                          <div>
                              <p class="pro_p1">防噪耳塞<br>2副装</p>
                              <p class="pro_p2">600积分</p>
                          </div>
                     </div>
                     <div class="pro_child">
                         <div class="pro_pic"><img src="../assets/jifen/jifen4.png" style="width:60px;margin-top:14px"></div>
                          <div>
                              <p class="pro_p1">纱手套5双</p>
                              <p class="pro_p2">600积分</p>
                          </div>
                     </div>
                     <div class="pro_child">
                         <div class="pro_pic"><img src="../assets/jifen/jifen5.png" style="width:60px;margin-top:14px"></div>
                          <div>
                              <p class="pro_p1">防锈套装</p>
                              <p class="pro_p2">600积分</p>
                          </div>
                     </div>
                     <div class="pro_child">
                         <div class="pro_pic"><img src="../assets/jifen/jifen6.png" style="width:56px;margin-top:14px"></div>
                          <div>
                              <p class="pro_p1" style="width:80px;margin-top:18px">好三由定制<br>羊皮本+笔</p>
                              <p class="pro_p2">640积分</p>
                          </div>
                     </div>
                     <div class="pro_child">
                         <div class="pro_pic"><img src="../assets/jifen/jifen7.png" style="width:56px;margin-top:26px;"></div>
                          <div> 
                              <p class="pro_p1" style="width:70px;margin-top:18px">腾讯视频<br>会员月卡</p>
                              <p class="pro_p2">1000积分</p>
                          </div>
                     </div>
                     <div class="pro_child">
                         <div class="pro_pic"><img src="../assets/jifen/jifen9.png" style="width:58px;margin-top:14px"></div>
                          <div>
                              <p class="pro_p1" style="margin-top:18px">暖宝宝贴<br>100片</p>
                              <p class="pro_p2">1200积分</p>
                          </div>
                     </div>
                     <div class="pro_child">
                          <div class="pro_pic"><img src="../assets/jifen/jifen8.png" style="width:40px;margin-top:14px"></div>
                          <div>
                              <p class="pro_p1" style="margin-top:18px;width:90px">法国润培roopy<br>护手霜一个</p>
                              <p class="pro_p2">1200积分</p>
                          </div>
                     </div>
                     <div class="pro_child">
                          <div class="pro_pic"><img src="../assets/jifen/jifen10.png" style="width:64px;margin-top:14px"></div>
                          <div>
                              <p class="pro_p1" style="margin-top:18px">40包抽纸<br>餐巾纸面巾纸</p>
                              <p class="pro_p2">1200积分</p>
                          </div>
                     </div>
                     <div class="pro_child">
                          <div class="pro_pic"><img src="../assets/jifen/jifen11.png" style="width:64px;margin-top:24px"></div>
                          <div>
                              <p class="pro_p1" style="margin-top:26px">40元话费</p>
                              <p class="pro_p2">1500积分</p>
                          </div>
                     </div>
                     <div class="pro_child">
                          <div class="pro_pic"><img src="../assets/jifen/jifen12.png" style="width:64px;margin-top:20px"></div>
                          <div>
                              <p class="pro_p1" style="margin-top:18px">肯德基<br>50元电子卡</p>
                              <p class="pro_p2">2000积分</p>
                          </div>
                     </div>
                     <div class="pro_child">
                          <div class="pro_pic"><img src="../assets/jifen/jifen13.png" style="width:64px;margin-top:24px"></div>
                          <div>
                              <p class="pro_p1" style="margin-top:18px">中石化加油卡<br>100元</p>
                              <p class="pro_p2">4000积分</p>
                          </div>
                     </div>
                </div>

               
            </div>
                <div class="getJifen">
                    <div class="getJifen_top">
                        <div class="ji">
                            <div class="line"></div>
                            <div class="jifen_tit">积分领取方式</div>
                            <div class="line"></div>
                        </div>
                    </div>

                    <div class="action1"> 
                         方式一：注册
                    </div>
                    <div style="padding:0 0px;margin-top:14px;">
                        <img src="../assets/jifen/app1.png" alt="" style="width:96%">
                        <p style="width:96%;margin:0 auto;margin-top:10px;margin-bottom:18px;color:#C04D01;text-align:justify;line-height:26px">打开好三由APP首页，点击“立即认证”，上传身份证信息，进行人脸识别，上传船舶资料。</p>
                    </div>
                    <div style="display:flex;marhin-top:18px;justify-content: space-between;">
                        <div class="action1">
                         方式二：签到
                        </div>
                        <div class="action1 action2">
                         方式三：船舶报空
                        </div>
                    </div>
                    <div class="app2">
                        <div>
                            <img src="../assets/jifen/app3.png" style="width:93%">
                            <p style="width:93%;margin:0 auto;text-align:justify;margin-top:10px;color:#C04D01;line-height:26px">打开好三由APP首页，点击右上角的“签到”。</p>
                        </div>
                        <div>
                            <img src="../assets/jifen/app2.png" style="width:93%">
                            <p style="width:93%;margin:0 auto;text-align:justify;margin-top:10px;color:#C04D01;line-height:26px">打开好三由APP首页，点击下方菜单栏中的“报空”，然后点击立即报空。</p>
                        </div>
                    </div>
                    <div class="action1 action2">
                         方式四：完成订单
                    </div>
                    <div class="app2" style="padding:18px 10px">
                          <div style="width:50%">
                              <p style="margin-top:50%;line-height:26px;text-align:justify;padding-right:20px;color:#C04D01;">登录好三由app，点击货场界面，选择合适的货源承接，完成订单后即可按照比例获取积分~快来接单吧！</p>
                          </div>
                          <div style="width:50%">
                              <img src="../assets/jifen/app4.png" style="width:100%">
                          </div>
                    </div>
                </div>  
               <div class="getJifen active1" style="margin-top:40px;padding-bottom:10px;padding-top:40px">
                   <div class="active">
                       活动规则   
                   </div>
                   <p>1、库存有限，先到先得，兑完即止；<br>
                      2、实物无质量问题不支持退换货；<br>    
                      3、积分一经使用不支持退还；<br>
                      4、以上活动最终解释权归江苏好三由信息科技有限公司所有；</p>
               </div>
                
            
        </div>
        <div class="banner5" v-if="bannerId == 3">
            <table></table>
                  <div class="five_mid1" style="margin-top:290px;height:auto;padding-bottom:14px">
                         <table></table>
                         <div class="mid1_title">
                             团体意外险
                         </div>
                         <div class="mid1_title2">
                             保费850元/人，最高可赔30万
                         </div>
                         <div class="mid1_title3">
                             服务范围广
                         </div>
                         <div class="mid1_device">
                             <p>人员：好三由智慧航运平台注册船员</p>
                             <p>年龄：16-65周岁<span style="margin-left:24px">保险期限：1年</span></p>
                             <p>司法管辖：中华人民共和国司法（港、澳、台除外）</p>
                         </div>
                         <div class="mid1_title3">
                             理赔金额大
                         </div>
                         <div class="mid1_table">
                             <table style="border:1px solid black;font-size:12px">
                                <tr>
                                  <td style="color:#5F71E7;">项目</td>
                                  <td style="color:#5F71E7;">金额</td>
                                </tr>
                                <tr>
                                  <td>意外身故、残疾</td>
                                  <td>30万</td>
                                </tr>
                                <tr>
                                  <td>意外医疗</td>
                                  <td>3万</td>
                                </tr>
                                <tr>
                                  <td>免赔</td>
                                  <td>500元</td>
                                </tr>
                                <tr>
                                  <td>赔付比例</td>
                                  <td>100%</td>
                                </tr>
                                <tr>
                                  <td>保费</td>
                                  <td>850元/人</td>
                                </tr>
                             </table>
                            
                         </div>

                  </div>
                  <div class="five_mid1" style="height:auto;padding-bottom:10px">
                       <table></table>
                        <div class="mid1_title">
                             重大疾病险
                         </div>
                         <div class="mid1_title2" style="width:200px">
                             保费低至33元起，保额最高20万
                         </div>
                         <div class="mid1_title3">
                             服务范围广
                         </div>
                         <div class="mid1_device">
                             <p>人员：好三由智慧航运平台注册船员</p>
                             <p>年龄：28天-65周岁</p>
                             <p>司法管辖：中华人民共和国司法（港、澳、台除外）</p>
                         </div>
                         <div class="mid1_title3">
                             保障更全面
                         </div>
                         <div class="mid1_device">
                             <p>覆盖28种重疾和3种轻症，包含多种常见疾病，保障更加齐全。</p>
                         </div>
                         <div class="mid1_title3">
                             确诊即可赔付
                         </div>
                         <div class="mid1_device">
                             <p>确诊后全额赔付，保多少赔多少。保费低至33元起保10万元，66元起保20万元。</p>
                         </div>
                  </div>
                  <div class="five_mid1" style="height:auto;padding-bottom:10px">
                       <table></table>
                         <div class="mid1_title">
                             大病医疗险
                         </div>
                         <div class="mid1_title2" style="width:130px">
                             家庭版，共享300万
                         </div>
                         <div class="mid1_title3">
                             服务范围广
                         </div>
                         <div class="mid1_device">
                             <p>年龄：0-60周岁,60-80岁仅针对于续保客户</p>
                             <p>医院范围：境内二级或二级以上公立医院或保险人认可的医疗机构的普通部 <span style="margin-left:24px">保险期限：1年</span></p>
                             <p>司法管辖：中华人民共和国司法（港、澳、台除外）</p>
                         </div>
                         <div class="mid1_title3">
                             理赔金额大
                         </div>
                         <div class="mid1_table">
                             <table style="border:1px solid black;font-size:12px">
                                <tr>
                                  <td style="color:#5F71E7;">保险项目</td>
                                  <td style="color:#5F71E7;">保险金额</td>
                                </tr>
                                <tr>
                                  <td>一般医疗费用补偿</td>
                                  <td style="color:#5F71E7;">共享300万</td>
                                </tr>
                                <tr>
                                  <td>恶性肿瘤费用补偿</td>
                                  <td style="color:#5F71E7;">共享300万</td>
                                </tr>
                             </table>
                            
                         </div>
                          <div class="mid1_title3">
                             全家人的保障
                         </div>
                         <div class="mid1_device">
                             <p>2-4人家庭，有社保保费低至136元起，无社保保费低236元起，共享免赔额1万元，共享保额300万元。</p>
                            
                         </div>

                  </div>
                  <div class="mid1_bot"><a style="color:white;" href="tel://15312027044">保险专属客服：15312027044</a></div>
        </div>
        <div class="banner6" v-if="bannerId == 5">
            <img src="../assets/banners/introduce.png" alt="" style="width:100%" />
        </div>
    </div>
</template>

<script>
export default {
    name: "Banner",
    props: {},
    data() {
        return {
            bannerId: 0,
            productList: [
                {
                    url: require("../assets/banners/product1.png"),
                    name: "防潮防霉包*10包",
                    point: "400",
                },
                {
                    url: require("../assets/banners/product2.png"),
                    name: "3M防噪耳塞2副装",
                    point: "600",
                },
                {
                    url: require("../assets/banners/product3.png"),
                    name: "好三由定制羊皮本+笔",
                    point: "640",
                },
               
                {
                    url: require("../assets/banners/product5.png"),
                    name: "六神花露水三件套180ml*3",
                    point: "1200",
                },
                {
                    url: require("../assets/banners/product6.png"),
                    name: "润本蚊香液6瓶+1个蚊香器",
                    point: "1200",
                },
                {
                    url: require("../assets/banners/product7.png"),
                    name: "40包抽纸餐巾纸面巾纸",
                    point: "1200",
                },
            ],
        };
    },
    watch: {
        // 监听地址栏变化
        $route: {
            handler(to, from) {
                this.bannerId = to.params.bannerId;
            },
            immediate: true,
        },
    },
    created(){
 console.log("====",this.$route)
    },
    mounted() {
         console.log("====",this.$route)
    },
    computed: {},
    methods: {},
    components: {},
};
</script>
<style scoped lang="scss">
.bannerWrapper{line-height:150%!important}
div,p{line-height:150%}
.banner {
    width: 100%;
    line-height:150%;
    background: #fff;
    font-size: 12px;
}
.content{text-align: center;background: url(../assets/insurance/banner.png) ;background-size: 100% 100%;}
.banner .content .pic1{display:block;position:absolute;top:-2px;left:0;width:375px}
.top_tit{
    width: 340px;
    // height: 160px;
    background-color: white;
    margin: 0 auto;
    border-radius: 10px;
    padding: 15px 15px;
    margin-top: 270px;
    position: relative;
}
.top_tit2{
    width: 310px;
    // height: 130px;
    background-color: #F4F9FE;
    color: black;
    border-radius: 10px;
    text-align: justify;
    padding: 20px 30px;
    line-height: 22px !important;
    font-size: 12px;
    line-height: 22px;
    position: relative;
}
.jiahao{
    position: absolute;
    top: 0;
    width: 20px;
    left: 10px;
}
.dun{
    position: absolute;
    width: 50px;
    right: 15px;
    top: -24px;
    z-index: 999999;
}
//为客户提供
.client{
width: 340px;
// height: 324px;
background-color: white;
margin: 0 auto;
margin-top: 14px;
border-radius: 10px;
padding: 10px;
}
.client_tit{
    width: 150px;
    height: 30px;
    margin: 0 auto;
    color: white;
    font-size: 16px;
    font-weight: 600;
    background-color: #3C77FC;
    display: flex;
    justify-content: space-evenly;
    border-radius: 12px;
    margin-bottom: 14px;
    padding-top: 3px;
}
.circle{
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: white;
    margin-top: 9px;
}
.client_item{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between ;
}
.client_child{
    width: 155px;
    height: 84px;
    background-color: #F4F9FE;
    margin-bottom: 10px;
    border-radius: 10px;
    display: flex;
    padding: 18px 10px;
}
.icon_img{
    width: 50px;
    
}
.client_text{margin-left: 0px;}
.client_text h2{
    margin: 0%;
    font-size: 16px;
    font-weight: bold;
    color: black;
    margin: 4px 0 4px 0;
}
.client_text h5{
    font-size: 10px;
    color: #948F90;
}
.client_text2{
    padding: 10px 18px;
    text-align: justify;
    line-height: 22px;
    font-size: 12px;
    color: black;
}
//01 02 03 04
.introduce{
    width: 340px;
    // height: 500px;
    margin: 0 auto;
    background-color: white;
    margin-top: 14px;
    border-radius: 10px;
    padding: 10px 10px;
}
.intro_child{
    width: 100%;
    height: 100%;
    background-color: #F4F9FE;
    border-radius: 10px;
    padding: 10px;
}
.num{
    width: 50px;
    height: 66px;
    line-height: 66px;
    background: linear-gradient(left,#ECE8E2,#F4F9FE);
}
.num>img{
    width: 40px;
}
.intro_child1{
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px dotted #EBEFF3;
}
.intro_text h2{
    font-size: 16px;
    color: #1C45CB;
    text-align: left;
    font-weight: bold;margin-bottom: 10px;
}
.intro_text{margin-left: 10px;}
.intro_text p{
    width: 240px;
    color: black;
    text-align: justify;
    margin: 0;
    font-size: 12px;
    line-height: 22px;
}
//footer
.foot{
    margin: 0 auto;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    color: white;
}
//船舶匹配
.banner2{
     width: 100%;
    line-height:150%;
    background: #fff;
    font-size: 12px;
}
.content2{
    width: 100%;
    height: 980px;
    text-align: center;
    background: url(../assets/images/chuan_banner.png) ;
    background-size: 100% 100%;
}
.chuan_content{
    width: 340px;
    height: 480px;
    background-color: white;
    margin:  0 auto;
    margin-top: 470px;
    border-radius: 6px;
    padding-top: 10px;
}
.chuan_content h2{
    width: 184px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    color: white;
    background: linear-gradient(left,#FD8D30,#FDBF4F);;
}
.chuan_content p{
    width: 300px;
    font-size: 12px;
    margin: 0 auto;
    text-align: justify;
    line-height: 22px;
    color: black;
    margin-top: 15px;
    margin-bottom: 15px;
    
}
.chuan_content h5{
    font-size: 12px;
    width: 300px;
    margin: 0 auto;
    text-align: justify;
    line-height: 22px;
    color: black;
}
//积分
.jifen{
    width: 340px;
    height: 720px;
    background-color: #FCF3D8;
    margin: 0 auto;
    margin-top: 410px;
    position: relative;
    padding: 0 10px;
    padding-top: 50px;
    border-radius: 10px;
}
.ji{
    width: 220px;
    height: 50px;
    line-height: 30px;
    display: flex;
    justify-content: space-evenly;
    background: url(../assets/jifen/little_banner.png);
    background-size: 100% 100%;
    position: absolute;
    top: -12px;
    padding: 12px;
    left: 60px;
}
.line{
    width: 20px;
    height: 1px;
    background-color: white;
    margin-top: 12px;
    
}
.jifen_tit{
    font-size: 18px;
    font-weight: bold;
    color: white;
}
.product{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}
.pro_child{
    width: 155px;
    height: 84px;
    margin-top: 10px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 0 6px;
}
.pro_pic>img{
    width: 70px;
}
.pro_p1{color: #C04D01;font-size: 12px;margin: 0;margin-top: 21px;line-height: 16px;text-align: right;}
.pro_p2{color: #FD804A;font-size: 14px;text-align: right;}

//积分领取方式
.getJifen{
    width: 340px;
    // height: 1400px;
    background-color: white;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 24px;
    position: relative;
    padding-top: 50px;
}
.action1{
    width: 120px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    color: white;
    background: linear-gradient(left,#FFAE45,#FE8108);
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}
.action2{ width: 150px;margin-right: 18px; }
.app2{display: flex;padding: 14px 0px;justify-content: space-evenly;}
.active{
    width: 180px;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    border-radius: 10px;
    font-size: 20px;
    color: white;
    background: linear-gradient(left,#97B3FF,#4975EC);
    position: absolute;
    top: -20px;
    left: 80px;
    }
    .active1 p{
        text-align: left;
        margin-left: 10px;
        line-height: 26px;
        color:#C04D01;
    }
    //船民险
.banner5{
    width: 100%;
    background-color: cadetblue;
    background-image: url(../assets/chuanminxian/banner_all.jpg);
    background-size: 100% 100%;
}
.five_mid1{
    width: 340px;
    height: 370px;
    background-color: white;
    margin: 0 auto;
    border-radius: 10px;
    padding: 0 14px;
    margin-bottom: 20px;
}
.mid1_title{
    width: 170px;
    background-image: url(../assets/chuanminxian/title_banner.png);
    background-size: 100% 100%;
    font-size: 20px;
    color: white;
    text-align: center;
    margin: 0 auto;
    margin-top: -1px;
}
.mid1_title2{
    width: 184px;
    background-color: #EFF1FD;
    font-size: 12px;
    color: #5F71E7;
    margin: 10px auto;
    text-align: center;
    border-radius: 10px;
}
.mid1_title3{
    font-size: 18px;
    color: #5F71E7;
    font-weight: bold;
    margin-bottom: 4px;
    margin-top: 6px;
}
.mid1_device{
    font-size: 12px;
    color: black;
}
.mid1_device p{
    margin-bottom: 0px;
    line-height: 22px;
}
.mid1_table table{
    line-height: 22px;
    text-align: center;
}
.mid1_bot{
    font-size: 16px;
    color: white;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 16px;
}
// 原来css

.desc {
    padding: 25px 14px 18px;
    text-align: justify;
    line-height:150%
}
.listZone {
    padding: 0 10px;
    border-left: 1px dashed #ccc;
}
.listItem h2 {
    display: inline-block;
    line-height: 20px;
    background: #f7c42e;
    font-size: 14px;
    color: #fff;
    padding-right: 30px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-left: 15px;
    position: relative;
    left: -14px;
}
.listItem p {
    line-height: 150%;
    margin-top: 8px;
    margin-bottom:10px;
}
.bottom {
    position: fixed;
    bottom: 0px;
    left: 0;
    background: #fff;
    height: 70px;
    padding-top: 15px;
}
table {
    width: 100%;
}
table tr td {
    border: 1px solid #000;
    width: 50%;
    text-align: center;
}
table tr th {
    color: #17b58e;
    font-size: 16px;
    border: 1px solid #000;
    width: 50%;
    text-align: center;
    height: 24px;
}
.blue{color:#336FF9;}


.banner3 {
    font-size: 12px;
    line-height: 150%;
    background: url("../assets/banners/banner31.png") no-repeat;
    background-size: 375px;
    padding-bottom: 60px;

    padding-top: 130px;
    .infoItem {
        width: 350px;
        margin: 10px auto;
        border: 4px solid #55e9c3;
        border-radius: 8px;
        background: #fff;
        padding: 30px 15px 10px;
        text-align: left;
        .bigTitle {
            font-size: 18px;
            color: #fff;
            font-weight: bold;
            text-align: center;
            line-height: 35px;
            width: 200px;
            border-radius: 5px;
            background: #d1981c;
            display: block;
            position: relative;
            top: -50px;
            left: 50px;
            box-shadow: 3px 6px 3px #fae1a9;
        }
        .titleZone {
            padding: 8px 15px;
            width: 340px;
            position: relative;
            left: -15px;
            text-align: center;
            background-image: linear-gradient(left, #fff8e9, #ffffff);
            .infoTitle {
                font-size: 16px;
                font-weight: bold;
                line-height: 30px;
            }
           
        }
        .insurance {
            h2 {
                font-size: 16px;
                font-weight: bold;
                color: #17b48d;
                line-height: 30px;
                margin-top: 10px;
                margin-bottom:8px;
            }
            p {
                margin-top: -8px;
                line-height:160%
            }
        }
    }
    .tel > a {
        display: block;
        width: 100%;

        text-align: center;
        font-size: 16px;
        color: #fff;
        box-shadow: 0 -2px 10px rgba(255, 255, 255, 0.5);
        position: fixed;
        left: 0;
        bottom: 0;
        img {
            width: 100%;
        }
    }
}
.banner4 {
    background: url(../assets/jifen/jifen_banner.png);
    background-size:100% 100%;
    font-size: 12px;
    text-align: center;
    padding-bottom:12px;
    .headerImg {
        display: block;
        width: 375px;
    }

    .contentZone {
        width: 345px;
        margin: 30px auto;
        background: #fff;
        border-radius: 16px;
        .zoneHeader {
            text-align: center;
            background: url(../assets/jifen/little_banner.png) no-repeat;
            background-position: 50% 50%;
            background-size: 200px 30px;
            width: 375px;
            height: 45px;
            color: #fff;
            font-size: 15px;
            line-height: 42px;
            position: relative;
            top: -20px;
            left: -15px;
        }
        .productZone {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            .productItem {
                width: 155px;
                height: 195px;
                img {
                    display: block;
                    border: 1px solid #ffdccd;
                    width: 100%;
                    border-radius: 8px;
                    padding: 22px 0;
                }
                .name {
                    line-height: 28px;
                }
                .point {
                    line-height: 10px;
                    font-size: 15px;
                    color: #ff8745;
                    font-weight: bold;
                }
            }
        }
        
        .infoTitle {
            width: 140px;
            height: 25px;
            margin: 0 auto;
            border-radius: 13px;
            background: #ff8745;
            color: #fff;
            font-size: 15px;
            text-align: center;
            line-height: 25px;
            margin-top: -15px;
        }
        .info1 {
            display: block;
            width: 325px;
            margin: 15px auto;
        }
        .info1Desc {
            width: 325px;
            margin: 15px auto;
            line-height: 150%;
            text-align: left;
        }
        .infoGroup {
            display: flex;
            justify-content: space-around;
            text-align: left;
            .info2 {
                width: 155px;
                border: 0px solid red;
                div {
                    margin-top: 10px;
                }
            }
            .infoTitle {
                margin: 15px auto;
            }
           
        }
        .info4 {
            margin-top: 25px;
        }
        .info4Group {
            display: flex;
            justify-content: space-around;
            margin-top: 15px;
            text-align:center;
            align-items: center;
            padding-bottom:15px;
            div {
                width: 155px;
                border: 0px solid red;
                img {
                    width: 100%;
                }
            }
             .text {
                position: relative;
                p {
                    position: absolute;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    border: 1px solid green;
                    color:green;

                }
            }
        }
        .activityInfo{text-align:left;padding:0 10px;padding-bottom:15px;margin-top:-10px;line-height:160%}
    }
}
</style>
